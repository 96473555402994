import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/organisms/layout';
import SEO from '../components/organisms/seo';
import { ChatInfo, ChatLink } from '../components/atoms/chatText';
import { ChatRichtextSanity } from '../components/atoms/chatRichtext';
import { ChatImage, ChatVideo } from '../components/atoms/chatMedia';
import ChatLeft from '../components/atoms/chatLeft';

const DrumherumPage = ({ data }) => {
  useEffect(() => {
    document.body.classList.add('yellow');
    return () => document.body.classList.remove('yellow');
  });

  const { content } = data.drumherum.nodes[0];
  const { drumherum } = data.gradients.nodes[0];

  return (
    <Layout bgSrc={drumherum.image.asset.fluid}>
      <SEO siteTitle="Drumherum" />
      <div className="chats" style={{ paddingBottom: 'var(--spacing-L)' }}>
        {content.map(
          (item, i) =>
            (item.__typename === 'SanityLink' && (
              <ChatLink key={i} link={item.url} text={item.text} />
            )) ||
            (item.__typename === 'SanityCopy' && (
              <ChatRichtextSanity key={i} left blocks={item._rawCopy} />
            )) ||
            (item.__typename === 'SanityMainImage' && (
              <ChatImage
                key={i}
                imgSrc={item.image.asset.fluid}
                imgAlt={item.alt}
              />
            )) ||
            (item.__typename === 'SanityVideo' && (
              <ChatVideo key={i} videoSrc={item.url} />
            )) ||
            (item.__typename === 'SanityTitle' && (
              <>
                {i !== 0 && (
                  <div style={{ paddingTop: 'var(--v-spacing-XL)' }} />
                )}
                <ChatLeft key={i} text={item.title} />
              </>
            )) ||
            (item.__typename === 'SanityInfo' && <ChatInfo text={item.info} />)
        )}
        <div style={{ paddingTop: 'var(--v-spacing-XL)' }} />
        <ChatLink stored internalLink="/team/" text="Schau dir das Team an" />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Drumherum {
    gradients: allSanityGradients {
      nodes {
        drumherum {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
    drumherum: allSanityDrumherum {
      nodes {
        content {
          ... on SanityInfo {
            info
          }
          ... on SanityCopy {
            _rawCopy
          }
          ... on SanityLink {
            text
            url
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
          ... on SanityTitle {
            title
          }
        }
      }
    }
  }
`;

DrumherumPage.propTypes = {
  data: PropTypes.object,
};

export default DrumherumPage;
